// This is for the content to be filled in the cards
export default [
  {
    id: 1,
    title: "Accredited Solar Designer",
    subtitle: "Accredited Solar Designer",
    descr: "",
    img: "Acc-1.jpg",
  },
  {
    id: 2,
    title: "Accredited Solar Installer",
    subtitle: "Accredited Solar Installer",
    descr: "",
    img: "Acc-2.jpg",
  },
  {
    id: 3,
    title: "National Electrical",
    subtitle: "Professional Membership",
    descr: "",
    img: "Acc-3.jpg",
  },
  {
    id: 4,
    title: "Licenced Electrical Contractor",
    subtitle: "Licenced Electrical Contractor",
    descr: "",
    img: "Acc-4.png",
  },
  {
    id: 5,
    title: "Registered Master cabler",
    subtitle: "Registered Master cabler",
    descr: "",
    img: "Acc-5.png",
  },
];
